
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppBody',
  props: {
    msg: String
  },
  data () {
    return {
      text: '',
      encodedText: '...'
    }
  },
  methods: {
    urlEncode () {
      this.encodedText = encodeURIComponent(this.text)
    }
  }
})
