import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag-next'
require('@/assets/main.scss')

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    property: {
      id: 'UA-193634946-5'
    }
  })
  .mount('#app')
